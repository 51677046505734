import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import parth from '../assets/parth.jpg'
import { Layout } from '../components/Layout'
import { Posts } from '../components/Posts'
import { SEO } from '../components/SEO'
import { Heading } from '../components/Heading'
import { Hero } from '../components/Hero'
import { PageLayout } from '../components/PageLayout'
import { projectsList } from '../data/projectsList'
import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

export default function Index({ data }) {
  const latestArticles = data.latestArticles.edges
  const articles = useMemo(
    () => getSimplifiedPosts(latestArticles),
    [latestArticles]
  )

  return (
    <>
      <Helmet title={config.siteTitle} />
      <SEO />

      <PageLayout>
        <Hero title="Hey, I'm Parth 👋" type="index">
          <div className="hero-wrapper">
            <div>
              <p className="hero-description">
                I am a software engineer specializing in the intersection of
                machine learning and data engineering.
              </p>
              <p className="hero-description">
                I enjoy writing technical articles, reading books, working out,
                photography, music mixing, and spending quality time with
                friends.
              </p>

              <p className="hero-description">
                On this site, you can explore my {' '}
                <Link to="/resume-parth-shandilya">portfolio</Link>, the{' '}
                <Link to="/blog">articles</Link> I've written, the{' '}
                <Link to="/projects">projects</Link> I've worked on, and learn
                more <Link to="/me">about me.</Link>{' '}
              </p>
            </div>
            <img src={parth} className="hero-image" alt="Parth Image" />
          </div>
        </Hero>

        <section className="section-index">
          <Heading
            title="Latest Articles"
            description="References and Foundational Insights."
            slug="/blog"
            buttonText="All Articles"
          />
          <Posts data={articles} newspaper />
        </section>

        <section>
          <Heading
            title="Projects"
            slug="/projects"
            buttonText="All Projects"
            description="Open-source projects I've worked on over the years."
          />
          <div className="cards">
            {projectsList
              .filter((project) => project.highlight)
              .map((project) => {
                return (
                  <div className="card" key={`hightlight-${project.slug}`}>
                    <time>{project.date}</time>
                    <a
                      href={`https://github.com/parths007/${project.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {project.name}
                    </a>
                    <p>{project.tagline}</p>
                    <div className="card-links">
                      {project.writeup && (
                        <Link className="button small" to={project.writeup}>
                          Article
                        </Link>
                      )}
                      {project.url && (
                        <a
                          className="button small"
                          href={project.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                        Demo
                        </a>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </section>
      </PageLayout>
    </>
  )
}

Index.Layout = Layout

export const pageQuery = graphql`
  query IndexQuery {
    latestArticles: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          template: { eq: "post" }
          categories: { ne: "Personal" } # Different from Original
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            categories
          }
        }
      }
    }
  }
`
